/* Call to Action Button */
.cta-btn {
  display: inline-block;
  position: relative;
  padding: 0.8rem $default-font-size;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  }
}

/* Hero Style */
.cta-btn--hero {
  @include supportColorForIE11();
  border-width: 2px;
  border-style: solid;
  -moz-border-image: -moz-linear-gradient(135deg,
      $primary-color 0%,
      $secondary-color 100%);
  -webkit-border-image: -webkit-linear-gradient(135deg,
      $primary-color 0%,
      $secondary-color 100%);
  border-image: linear-gradient(135deg,
      $primary-color 0%,
      $secondary-color 100%);
  -webkit-border-image-slice: 1;
  border-image-slice: 1;

  @include supportIE11() {
    color: $secondary-color !important;

    &:hover {
      color: $white-color !important;
    }
  }

  @include respond(phone) {
    background-image: none;
    border: 2px solid $secondary-color;
    -webkit-text-fill-color: $secondary-color;
  }

  &::after {
    background-image: linear-gradient(135deg,
        $primary-color 0%,
        $secondary-color 100%);

    @include respond(phone) {
      background-image: none;
    }
  }

  &:hover {
    -webkit-text-fill-color: $white-color;
    text-decoration: none;

    @include respond(phone) {
      -webkit-text-fill-color: $secondary-color;
    }

    &::after {
      width: 100%;
    }
  }
}

/* Resume Style */
.cta-btn--resume {
  color: $white-color;
  border: 2px solid $white-color;

  &::after {
    background: $white-color;
  }

  &:hover {
    color: $secondary-color;
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
}

/* Arrow Button */
.up i {
  color: #272727;
}

.blue-btn {
  font-size: 16px;
  font-weight: 700;
  padding: 12px 32px;
  justify-content: center;
  letter-spacing: -0.032px;
  text-align: center;
  flex-wrap: nowrap;
  max-width: 100%;
  display: inline-flex;
  position: relative;
  background: none;
  background-color: $primary-color;
  background-clip: border-box;
  color: white;
  border-width: 1.6px;
  border-style: solid;
  border-color: $primary-color;
  border-radius: 4px;
  white-space: normal;
  user-select: none;
  align-items: center;
  appearance: none;
}

.blue-btn:hover {
  color: white;
  background-color: $dark-blue-text;
  border-color: $dark-blue-text;
}

.blue-btn:focus {
  background-color: $dark-blue-text;
  border-color: $dark-blue-text;
  outline: 5px solid #1b96ff;
  box-shadow: $primary-color 0 0 3px;
}

.white-btn {
  font-size: 16px;
  font-weight: 700;
  padding: 12px 32px;
  justify-content: center;
  letter-spacing: -0.032px;
  text-align: center;
  flex-wrap: nowrap;
  max-width: 100%;
  display: inline-flex;
  position: relative;
  background: none;
  background-color: white;
  background-clip: border-box;
  color: $primary-color;
  border-width: 1.6px;
  border-style: solid;
  border-color: $primary-color;
  border-radius: 4px;
  white-space: normal;
  user-select: none;
  align-items: center;
  appearance: none;
}

.white-btn:hover {
  color: #023248;
  background-color: #eaf5fe;
  border-color: #023248;
}

.white-btn:focus {
  background-color: #eaf5fe;
  border-color: #023248;
  color: #023248;
  outline: 5px solid #1b96ff;
  box-shadow: $primary-color 0 0 3px;
}