#about {
  background-image: url(https://wp.salesforce.com/en-us/wp-content/uploads/sites/4/2023/04/n-up-sales-cloud-background-customer.webp);
  color: $dark-blue-text;
  height: 100%;
  border-top: 0px;
  padding-bottom: 10%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 90%;

  @include respond(tab-land) {
    height: 100%;
    -webkit-clip-path: none;
    clip-path: none;
  }

  .about-wrapper {
    box-shadow: 0 2px 8px -2px rgba(24,24,24,.08), 0 8px 9pt -2px rgba(24,24,24,.16);
    background-color: white;
    padding: 4pc 110px;
    border-radius: 1pc;
    box-sizing: border-box;
    border-width: 0;

    @include respond(phone) {
      padding-bottom: 5rem;
      padding-left: 0;
      padding-right: 0;
    }

    &__image {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;

      @include respond(tab-land) {
        height: 100%;
      }
      @include respond(tab-port-sm) {
        padding-bottom: 6.4rem;
      }
    }

    &__info {
      display: flex;
      height: 100%;
      justify-content: center;
      flex-direction: column;

      @include respond(tab-port-sm) {
        align-items: center;
      }

      &-text {
        text-align: left;
        font-size: 1pc;
        color: #181818;
        letter-spacing: -.0012em;
        line-height: 24px;

        @include respond(tab-port) {
          text-align: left;
        }
        @include respond(tab-port-sm) {
          text-align: center;
        }

        &--important {
          background: darken($secondary-color, 1%);
          display: inline-block;
          font-style: italic;
          padding: 0 0.3rem;
          margin: 0.3rem 0;
          line-height: 1.6;

          @include respond(phone) {
            display: inline;
            margin: 0;
            padding: 0;
            background: transparent;
            font-style: normal;
          }
        }
      }
    }
  }
}
