.footer {
  background-color: $dark-blue-text;
  color: $white-color;
  padding-bottom: 10px;
  padding-top: 20px;

  @include respond(phone) {
    border: 0px;
  }

  &__text {
    color: $white-color;
    font-size: 11px;

    a {
      color: $primary-color;
      font-size: 11px;
      transition: all 0.4s;
      display: inline-block;
      background-color: $dark-blue-text;

      &:hover,
      &:active {
        color: $white-color;
      }
    }
  }

  & hr {
    margin: 1rem auto;
    border: 0;
    width: 50%;
    border-top: 2px solid grey;
  }
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white-color;
    font-size: 3rem;
    width: 5rem;
    height: 2rem;
    margin: $default-font-size $default-font-size;
    transition: all 0.2s ease-in;
  }
}

.back-to-top i {
  color: $white-color;
  margin: 1rem 0 $default-font-size;
  transition: all 0.2s ease-in;

  &:hover {
    transform: translateY(-2px);
  }
}
