// COLORS
$primary-color: #0176d3;
$secondary-color: #006DCC;

$white-color: #fff;

$dark-grey: #3e3e3c;
$light-grey: #f4f6f9;

$dark-blue-text: #032d60; // For Headings

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 3rem;
