#contact {
  background-color: white;
  padding: 15rem 0 10rem 0;
  margin-top: -10rem;
  margin-bottom: -1px;
  color: $dark-blue-text;

  @include respond(tab-land) {
    padding: 10rem 0;
    clip-path: none;
    margin-top: 0;
    -webkit-clip-path: none;
  }

  .contact-wrapper {
    margin-top: 3.2rem;
    padding: 0 2rem;
    backface-visibility: hidden;

    &__text {
      margin-bottom: 2.5rem;
      font-size: 18px;
      line-height: 28px;
      color: #181818;
      letter-spacing: -.0012em;
      line-height: 24px;
    }

    &__text,
    a {
      @include respond(phone) {
        font-size: 2rem;
      }
    }

    a {
      font-size: 16px;
    }
  }
}
